import { ZoomMtg } from "@zoomus/websdk";
import React, { useCallback, useEffect } from "react";
import "./App.css";
import { appConfig } from "./config/config";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.18.2/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

function App() {
  const params = new URLSearchParams(window.location.search);
  const meeting = params.get("meeting");
  const password = params.get("password");
  const username = params.get("username");
  const hostID = params.get("hostID");
  const userID = params.get("userID");
  const companyID = params.get("companyID");

  const role = hostID ? 1 : 0;

  const startMeeting = useCallback((responseData, meetingData) => {
    ZoomMtg.init({
      leaveUrl: `${appConfig.API_URL}/zoom/end-meeting?meeting_id=${meetingData.meeting}&host_id=${meetingData.hostID}&user_id=${meetingData.userID}`,
      disablePreview: true,
      success: (success) => {
        console.log(success);
        const element = document.getElementById("zmmtg-root");
        element.style.display = "block";
        ZoomMtg.join({
          signature: responseData.signature,
          sdkKey: responseData.sdk_client_id,
          meetingNumber: meetingData.meeting,
          passWord: meetingData.password,
          userName: meetingData.username,
          // userEmail: userEmail,
          // tk: registrantToken,
          // zak: zakToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }, []);

  const getSignature = useCallback(
    (meetingData) => {
      fetch(`${appConfig.API_URL}/zoom/signature`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingNumber: meetingData.meeting,
          role: meetingData.role,
          companyId: meetingData.companyID,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          startMeeting(response.data, meetingData);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [startMeeting]
  );

  useEffect(() => {
    if (meeting) {
      const meetingData = {
        meeting,
        username,
        password,
        role,
        hostID,
        userID,
        companyID,
      };
      getSignature(meetingData);
    } else {
      console.log("Meeting Number not found in the URL.");
    }
  }, [
    meeting,
    username,
    password,
    role,
    hostID,
    userID,
    companyID,
    getSignature,
  ]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "#111111",
      }}
    >
      <span
        style={{
          width: "48px",
          height: "48px",
          border: "5px solid #FFF",
          borderBottomColor: "#FF3D00",
          borderRadius: "50%",
          display: "inline-block",
          boxSizing: "border-box",
          animation: "rotation 1s linear infinite",
        }}
      ></span>
    </div>
  );
}

export default App;
